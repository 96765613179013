/**
 * This mixin implements CRUD using API calls to Laravel
 */
export default {
  data() {
    return {
      apiQuery: "/",
      showDeleteDialog: false,
      loading: false,
      total: 0,
      totals: {},
      items: [],
      options: {},
      meta: {},
      errorMessage: null,
    };
  },

  watch: {
    options: {
      handler() {
        this.loading = true;
        this.get(this.apiQuery, this.options)
          .then((data) => {
            if (data.hasOwnProperty.call(data, "data")) {
              this.items = data.data;
              this.total = data.total;
              this.totals = data.totals;
            } else {
              this.items = data;
              this.total = data.length;
              this.totals = {};
            }
            this.loading = false;
          })
          .catch((error) => {
            alert(error.message);
            this.loading = false;
            this.$emit("serverError", error);
          });
      },
      deep: true,
    },
  },

  methods: {
    onAdd() {
      this.$refs.editForm.addItem();
    },
    onShow(item) {
      this.$refs.showDialog.showUserDialog(item, this.items.indexOf(item));
    },
    onEdit(item) {
      this.$refs.editForm.editItem(item, this.items.indexOf(item));
    },

    onSaved(item, index) {
      if (index === -1) {
        this.items.push(item);
        this.total++;
      } else {
        Object.assign(this.items[index], item);
      }
      window.setTimeout(() => {
        this.$refs.editForm.closeForm();
      }, 2500);
    },

    onDelete(item) {
      this.$refs.deleteConfirm.deleteItem(item, this.items.indexOf(item));
    },

    onDeleted(item, index) {
      this.items.splice(index, 1);
      this.total--;
    },

    forSelect(obj, all = true) {
      const data = [];
      if (all)
        data.push({
          id: null,
          text: this.$t("ALL"),
        });
      for (const i in obj) {
        data.push({
          id: i,
          text: obj[i],
        });
      }
      return data;
    },

    reload(index) {
      this.$set(this.options, "reload", index + '' + Date.now());
    }
  },
};
